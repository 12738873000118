import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios'; // Adjust the path based on your project structure
import Button from '../components/Button'; // Import Button component
import '../styles/AddFacility.css'; // Import CSS for styling

const AddFacility = () => {
  const [facility, setFacility] = useState({
    facility_name: '',
    facility_type: '',
    facility_group: '',
    facility_address: '',
    facility_city: '',
    facility_state: '',
    facility_zip: '',
    facility_phone: '',
    facility_email: '', // Added email field
    facility_contact_first_name: '',
    facility_contact_last_name: '',
    facility_xray_rate: '',
    facility_us_rate: '',
    facility_ekg_rate: '',
  });
  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFacility({
      ...facility,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/facilities/add/', facility);
      if (response.status === 201) {
        setSuccessMessage('Facility added successfully!');
        setErrorMessage('');
        // Navigate back to the facility list page after a short delay
        setTimeout(() => {
          navigate('/facility-setup');
        }, 2000);
      }
    } catch (error) {
      console.error('Error adding facility:', error);
      setErrorMessage('Failed to add facility. Please try again.');
    }
  };

  return (
    <div className="add-facility">
      <h2>Add Facility</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Facility Name</label>
          <input
            type="text"
            name="facility_name"
            value={facility.facility_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Facility Type</label>
          <input
            type="text"
            name="facility_type"
            value={facility.facility_type}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility Group</label>
          <input
            type="text"
            name="facility_group"
            value={facility.facility_group}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility Address</label>
          <input
            type="text"
            name="facility_address"
            value={facility.facility_address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility City</label>
          <input
            type="text"
            name="facility_city"
            value={facility.facility_city}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility State</label>
          <input
            type="text"
            name="facility_state"
            value={facility.facility_state}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility ZIP</label>
          <input
            type="text"
            name="facility_zip"
            value={facility.facility_zip}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility Email</label> {/* Added email field */}
          <input
            type="text"
            name="facility_email"
            value={facility.facility_email}
            onChange={handleChange}
          />
        </div>
        {/* Add more fields as necessary */}
        
        <Button type="submit" gradient>Add Facility</Button>
        <Button onClick={() => navigate('/facility-setup')} gradient>Cancel</Button>
      </form>
    </div>
  );
};

export default AddFacility;
