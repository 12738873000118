import React, { useState, useEffect } from 'react';
import axios from '../utils/axios'; // Adjust the path based on your project structure
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Layout from '../components/Layout';
import TableEdit from '../components/TableEdit'; // Import the TableEdit component
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner component
import '../styles/QuerySent.css'; // Create CSS file for styling if needed

const QuerySent = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    const columns = [
        { Header: 'Full Name', accessor: 'full_name' },
        { Header: 'DOB', accessor: 'dob' },
        { Header: 'Facility Name', accessor: 'facility_name' },
        { Header: 'Date of Service', accessor: 'date_of_service' },
        { 
            Header: 'Skilled Patient', 
            accessor: 'skilled_patient', 
            isEditable: true,
            editType: 'dropdown', 
            options: ['SKILLED', 'NOT SKILLED', 'QUERY SENT & PENDING', 'NOT A SNF PATIENT', 'YET TO SEND THE QUERY', 'EXCEPTION- NO EMAIL ADDRESS'],
        },
        { 
            Header: 'Insurance Name', 
            accessor: 'insurance_name', 
            isEditable: true,
            editType: 'input', 
            clearable: true, 
        },
        { 
            Header: 'Billing Status', 
            accessor: 'billing_status', 
            isEditable: true,
            editType: 'dropdown', 
            options: ['PENDING BILLING', 'READY TO BILL', 'QUERY PENDING'],
        },
    ];

    // Fetch data from the backend
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get('/api/query-sent-pending/');
                setData(Array.isArray(response.data.data) ? response.data.data : []);
            } catch (error) {
                setErrorMessage('Failed to fetch data from the server.');
                console.error('Error fetching query sent data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Handle saving changes
    const handleSave = async (updatedData) => {
        try {
            const response = await axios.post('/api/query-sent-pending/', { updated_data: updatedData });
            if (response.status === 200) {
                setErrorMessage('Data saved successfully.');
                setData(updatedData); // Update local data with saved changes
            } else {
                setErrorMessage('Failed to save changes.');
            }
        } catch (error) {
            setErrorMessage('An error occurred while saving changes.');
            console.error('Error saving changes:', error);
        }
    };

    // Handle cancel changes
    const handleCancel = () => {
        // Reload the data to cancel changes
        setData([...data]);
    };

    // Handle navigating back
    const handleBack = () => {
        navigate('/misc-worklist');
    };

    return (
        <Layout>
            <div className="query-sent-page">
                <h2>Query Sent & Pending</h2> 
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {isLoading ? (
                    <LoadingSpinner /> // Show spinner during loading
                ) : (
                    <TableEdit columns={columns} data={data} onSave={handleSave} onCancel={handleCancel} onBack={handleBack} />
                )}
            </div>
        </Layout>
    );
};

export default QuerySent;
