import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import TableAdvanced from '../components/TableAdvanced';
import Button from '../components/Button';
import Layout from '../components/Layout';
import { FaRegCopy } from 'react-icons/fa';

const WebsiteCredentials = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State to store search query
    const [filteredCredentials, setFilteredCredentials] = useState([]);
    const [copiedItem, setCopiedItem] = useState('');

    useEffect(() => {
        fetchWebsiteCredentials();
    }, []);

    const fetchWebsiteCredentials = async () => {
        try {
            const response = await axios.get('/api/website-credentials/');
            setCredentials(response.data);
            setFilteredCredentials(response.data); // Initialize filtered data
        } catch (error) {
            console.error("Error fetching credentials:", error);
        }
    };

    // Handle search input
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        
        // Filter credentials by website name
        const filtered = credentials.filter(cred =>
            cred.website_name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCredentials(filtered);
    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.post(`/api/website-credentials/delete/${id}/`);
            fetchWebsiteCredentials();
        } catch (error) {
            console.error("Error deleting credential:", error);
        }
    };

    const handleEditClick = (id) => {
        navigate(`/edit/${id}`);
    };

    const handleHomeClick = () => navigate('/');

    // Handle copy to clipboard with feedback
    const handleCopyToClipboard = (text, itemId) => {
        navigator.clipboard.writeText(text);
        setCopiedItem(itemId);
        setTimeout(() => setCopiedItem(''), 2000); // Reset after 2 seconds
    };

    // Export data as CSV
    const exportData = async () => {
        try {
            const response = await axios.get('/api/website-credentials/export/', {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'website_credentials.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    // Columns for TableAdvanced component
    const columns = [
        { Header: 'Website Name', accessor: 'website_name' },
        { 
            Header: 'URL', 
            accessor: 'website_url', 
            style: { width: '250px' },
            Cell: ({ value }) => (
                <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <span style={{
                        overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block', width: '100px',
                        fontWeight: copiedItem === value ? 'bold' : 'normal'
                    }}>
                        {value}
                    </span>
                    <FaRegCopy onClick={() => handleCopyToClipboard(value, value)} style={{ cursor: 'pointer' }} />
                </span>
            )
        },
        { Header: 'Username', accessor: 'username' },
        { 
            Header: 'Password', 
            accessor: 'password', 
            Cell: ({ value }) => (
                <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <span style={{ fontWeight: copiedItem === value ? 'bold' : 'normal' }}>
                        {value || 'N/A'}
                    </span>
                    {value && <FaRegCopy onClick={() => handleCopyToClipboard(value, value)} style={{ cursor: 'pointer' }} />}
                </span>
            ),
        },
        { Header: 'Security Questions', accessor: 'security_questions' },
        { Header: 'Comments', accessor: 'comments' },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
                    <Button onClick={() => handleEditClick(row.original.id)} gradient>Edit</Button>
                    <Button onClick={() => handleDeleteClick(row.original.id)} gradient>Delete</Button>
                </div>
            ),
        },
    ];
    
    return (
        <Layout>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <Button onClick={() => navigate('/add-website')} gradient>Add New</Button>
                <Button onClick={() => navigate('/upload-csv')} gradient>Upload CSV</Button>
                <Button onClick={exportData} gradient>Export</Button>
                <Button onClick={handleHomeClick} gradient>Home</Button>
            </div>

            {/* Search box */}
            <div style={{ marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search by Website Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{
                        padding: '8px',
                        width: '100%',
                        maxWidth: '300px',
                        borderRadius: '4px',
                        border: '1px solid #ccc'
                    }}
                />
            </div>

            {/* Table with fixed row height */}
            <TableAdvanced
                columns={columns}
                data={filteredCredentials}
                style={{ rowStyle: { height: '50px' } }} // Fixed row height
            />
        </Layout>
    );
};

export default WebsiteCredentials;
