import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from '../utils/axios'; // Adjust path based on your project structure
import Button from '../components/Button'; // Import the existing Button component
import Layout from '../components/Layout'; // Import the Layout component
import Modal from '../components/Modal'; // Import the Modal component
import '../styles/FacilityVerificationUpload.css'; // Import the CSS file to apply the same styles

const MailMergeUpload = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false); // State for the upload button
    const [isUpdating, setIsUpdating] = useState(false); // State for the update button
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const navigate = useNavigate(); // Initialize the navigate function

    // Handle file change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        setIsUploading(true); // Set uploading state
        const formData = new FormData();
        formData.append('excel_file', file);

        try {
            const response = await axios.post('/api/mail-merge/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setMessage(response.data.message);
        } catch (error) {
            setMessage('Failed to send emails.');
            console.error('Error:', error);
        } finally {
            setIsUploading(false); // Reset uploading state
            setIsModalOpen(true); // Show the modal
        }
    };

    // Handle "Update the Status"
    const handleUpdateStatus = async () => {
        setIsUpdating(true); // Set updating state
        try {
            const response = await axios.post('/api/update-google-sheet-query-sent-now/');
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Failed to update the status.');
            console.error('Error:', error);
        } finally {
            setIsUpdating(false); // Reset updating state
            setIsModalOpen(true); // Show the modal
        }
    };

    // Handle modal close
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Layout>
            <div className="facility-verification-upload">
                <h2>Mail Merge Upload</h2>
                <input type="file" onChange={handleFileChange} />
                <div className="button-container"> {/* Use the button container class */}
                    <Button onClick={handleUpload} gradient disabled={isUploading}>
                        {isUploading ? 'Emailing...' : 'Upload and Send Emails'}
                    </Button>
                    <Button onClick={handleUpdateStatus} gradient disabled={isUpdating}>
                        {isUpdating ? 'Updating...' : 'Update the Status'}
                    </Button>
                    <Button onClick={() => navigate('/misc-worklist')} gradient>
                        Go to Misc Worklist
                    </Button>
                </div>

                {/* Modal to show the response message */}
                <Modal show={isModalOpen} message={message} onClose={closeModal} />
            </div>
        </Layout>
    );
};

export default MailMergeUpload;
