import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios';  // Assuming you have this axios instance configured
import Layout from '../components/Layout';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner';
import TableAdvanced from '../components/TableAdvanced';
import '../styles/Dashboard.css';

const StatusRecordsPage = () => {
  const { status } = useParams(); // Get the selected billing status from the URL
  const [records, setRecords] = useState([]);
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const tableColumns = [
    { Header: 'Full Name', accessor: 'full_name' },
    { Header: 'DOB', accessor: 'dob' },
    { Header: 'MRN', accessor: 'MRN' },
    { Header: 'Date of Service', accessor: 'date_of_service' },
    { Header: 'Accession', accessor: 'accession' },
    { Header: 'Facility Name', accessor: 'facility_name' },
    { Header: 'POS Type', accessor: 'pos_type' },
    { Header: 'Modality', accessor: 'modality' },
    { Header: 'Coding Status', accessor: 'coding_status' },
    { Header: 'Skilled Patient', accessor: 'skilled_patient' },
    { Header: 'Billing Status', accessor: 'billing_status' },
    {
      Header: 'Action', 
      accessor: 'billing_status_action', // Unique accessor for the action column
      Cell: ({ row }) => (
        <span
          className="clickable"
          onClick={() => {
            const id = row.original.id;
            if (id) {
              navigate(`/billingedit/${id}`);
            } else {
              console.error('Record ID is missing');
              alert('Unable to navigate: Record ID is missing.');
            }
          }}
        >
          Edit
        </span>
      ),
    },
  ];

  // Fetch records for the selected status
  useEffect(() => {
    const fetchRecords = async () => {
      setRecordsLoading(true);
      try {
        const response = await axios.get(`/api/records/?billing_status=${status}`);
        setRecords(response.data.records);
        setError(null);
      } catch (err) {
        console.error('Error fetching records:', err);
        setError('Failed to load records. Please try again.');
      } finally {
        setRecordsLoading(false);
      }
    };

    fetchRecords();
  }, [status]);

  return (
    <Layout>
      <div className="status-records-page">
        <h1>Records for {status}</h1>

        {recordsLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <div className="records-section">
            <div className="table-header">
              <div>
                <Button
                  gradient
                  onClick={() => navigate('/dashboard')}
                  >
                  Back to Dashboard
                </Button>
              </div>
            </div>
            <TableAdvanced columns={tableColumns} data={records} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default StatusRecordsPage;
