import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import Layout from '../components/Layout';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [billingStatusCounts, setBillingStatusCounts] = useState({});
  const [dbBillingStatusCounts, setDbBillingStatusCounts] = useState({}); // New state for database counts
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [syncing, setSyncing] = useState(false); // State for syncing status
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch data for Google Sheets
        const response = await axios.get('/api/dashboard/');
        setBillingStatusCounts(response.data.billing_status_counts);
        setError(null);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    const fetchDbDashboardData = async () => {
      try {
        // Fetch data for the database
        const response = await axios.get('/api/db-dashboard/');
        setDbBillingStatusCounts(response.data.billing_status_counts);
        setError(null);
      } catch (err) {
        console.error('Error fetching database dashboard data:', err);
        setError('Failed to load database dashboard data. Please try again.');
      }
    };

    fetchDashboardData();
    fetchDbDashboardData();
  }, []);

  const handleSync = async () => {
    setSyncing(true);
    try {
      const response = await axios.post('/api/sync-gsheet/');
      alert(response.data.message || 'Sync completed successfully!');
    } catch (err) {
      console.error('Error syncing data:', err);
      alert('Failed to sync data. Please try again.');
    } finally {
      setSyncing(false);
    }
  };

  return (
    <Layout>
      <div className="dashboard-page">
        <h1>Dashboard</h1>
        <div className="actions">
          <Button
            gradient
            onClick={() => window.location.reload()}
            className="dashboard-button"
          >
            🔄 Refresh
          </Button>
          <Button
            gradient
            onClick={() => navigate('/home')}
            className="dashboard-button"
          >
            🏠 Home
          </Button>
          <Button
            gradient
            onClick={handleSync}
            className="dashboard-button"
            disabled={syncing} // Disable button while syncing
          >
            {syncing ? 'Syncing...' : 'Sync Data'}
          </Button>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <>
            <div className="dashboard-widgets">
              <h2>Google Sheets Data</h2>
              {Object.entries(billingStatusCounts).map(([status, count]) => (
                <div
                  key={status}
                  className="dashboard-widget"
                  onClick={() => navigate(`/status-records/${status}`)} // Navigate to status records page
                >
                  <h3>{status}</h3>
                  <p>{count} records</p>
                </div>
              ))}
            </div>
            <div className="dashboard-widgets">
              <h2>Database Data</h2>
              {Object.entries(dbBillingStatusCounts).map(([status, count]) => (
                <div
                  key={status}
                  className="dashboard-widget"
                  onClick={() => navigate(`/db-status-records/${status}`)} // Navigate to DB status records page
                >
                  <h3>{status}</h3>
                  <p>{count} records</p>
                </div>
              ))}
            </div>

          </>
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;
