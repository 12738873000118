import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import '../styles/SidebarMenu.css';

const SidebarMenu = () => {
  const [arWorklistOpen, setArWorklistOpen] = useState(false);
  const [billingWorklistOpen, setBillingWorklistOpen] = useState(false);
  const [systemSetupOpen, setSystemSetupOpen] = useState(false); // State for the system setup submenu
  const navigate = useNavigate(); // Initialize useNavigate

  const toggleSubmenu = (menu) => {
    if (menu === 'arWorklist') {
      setArWorklistOpen(!arWorklistOpen);
    } else if (menu === 'billingWorklist') {
      setBillingWorklistOpen(!billingWorklistOpen);
    } else if (menu === 'systemSetup') {
      setSystemSetupOpen(!systemSetupOpen); // Toggle for the system setup submenu
    }
  };

  return (
    <div className="sidebar">
      <h2 className="sidebar-title">Worklist</h2>
      <ul className="sidebar-menu">
        <li className="menu-item">
          <div className="menu-header" onClick={() => toggleSubmenu('arWorklist')}>
            AR Worklist
          </div>
          <ul className={`submenu ${arWorklistOpen ? 'open' : ''}`}>
            <li><a href="/ar-follow-up">AR Follow-up</a></li>
            <li><a href="/ar-help">AR - Help</a></li>
            <li><a href="/ar-support-files">AR - Support Files</a></li>
          </ul>
        </li>
        <li className="menu-item">
          <div className="menu-header" onClick={() => toggleSubmenu('billingWorklist')}>
            Billing Worklist
          </div>
          <ul className={`submenu ${billingWorklistOpen ? 'open' : ''}`}>
            <li><a href="/dashboard">Billing Review</a></li>
            <li><a href="/billing-approval">Billing Appoval</a></li>
            <li><a href="/billing-reports">Billing Reports</a></li>
          </ul>
        </li>
        <li className="menu-item">
          <div className="menu-header" onClick={() => navigate('/misc-worklist')}>
            Misc Worklist
          </div>
        </li>
        <li className="menu-item">
          <div className="menu-header" onClick={() => toggleSubmenu('systemSetup')}>
            System Setup
          </div>
          <ul className={`submenu ${systemSetupOpen ? 'open' : ''}`}>
            <li><a href="/payer-setup">Payer Setup</a></li>
            <li><a href="/facility-setup">Facility</a></li>
            <li><a href="/user-management">User Management</a></li>
          </ul>
        </li>
        <li className="menu-item">
          <div className="menu-header" onClick={() => navigate('/process-automation')}>
            Process Automation
          </div>
        </li>
        <li className="menu-item">
          <div className="menu-header" onClick={() => navigate('/website-credentials')}>
            Web Portal Management
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SidebarMenu;
