import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import Layout from '../components/Layout';
import Button from '../components/Button';
import '../styles/AddWebsite.css'; // Assuming you store the CSS in styles folder

const AddWebsite = () => {
    const navigate = useNavigate();
    const [newWebsite, setNewWebsite] = useState({
        website_name: '',
        website_url: '',
        username: '',
        password: '',
        security_questions: '',
        comments: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewWebsite((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddWebsiteSubmit = async () => {
        try {
            await axios.post('/api/website-credentials/add/', newWebsite);
            navigate('/website-credentials');
        } catch (error) {
            console.error("Error adding new website:", error);
        }
    };

    return (
        <Layout>
            <div className="add-website">
                <h2>Add New Website</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                        <label>Website Name</label>
                        <input
                            type="text"
                            name="website_name"
                            value={newWebsite.website_name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Website URL</label>
                        <input
                            type="url"
                            name="website_url"
                            value={newWebsite.website_url}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            name="username"
                            value={newWebsite.username}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            value={newWebsite.password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Security Questions</label>
                        <input
                            type="text"
                            name="security_questions"
                            value={newWebsite.security_questions}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Comments</label>
                        <textarea
                            name="comments"
                            value={newWebsite.comments}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="button-group">
                        <Button onClick={handleAddWebsiteSubmit} gradient>Submit</Button>
                        <Button onClick={() => navigate('/website-credentials')} gradient>Cancel</Button>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default AddWebsite;
