import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import UserForm from '../components/UserForm';
import axios from '../utils/axios';
import '../styles/EditUser.css';

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    empId: '',
    email: '',
    emailAddress2: '',
    phoneNumber: '',
    location: '',
    userType: 'user',
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`/api/users/details/${id}/`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Failed to fetch user details');
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/users/edit/`, { ...formData, id });
      if (response.status === 200) {
        navigate('/user-list');
        alert('User updated successfully');
      }
    } catch (error) {
      console.error('Error updating user', error);
      setError('Failed to update user');
    }
  };

  return (
    <Layout>
      <div className="edit-user">
        <h2>Edit User</h2>
        {error && <p className="error-message">{error}</p>}
        <UserForm formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} isEdit />
        <button onClick={() => navigate('/user-list')} className="back-button">Back to User List</button>
      </div>
    </Layout>
  );
};

export default EditUser;
