import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner'; // Import LoadingSpinner
import logo from '../components/logo.PNG'; // Import the logo
import '../styles/LoginPage.css';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // For loading spinner
  const [errorMessage, setErrorMessage] = useState(''); // For error messages
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(''); // Clear previous error message

    try {
      await login(username, password);
      navigate('/home');
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage(
        error.response?.data?.message || 'Invalid username or password. Please try again.'
      ); // Set error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="header">
        <div className="logo-container">
          <img src={logo} alt="Bigbill AR Logo" className="logo" />
          <span className="logo-text">Bigbill AR Portal</span>
        </div>
      </div>
      <div className="login-container">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="login-form">
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="login-form-element"
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-form-element"
                required
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Show error message */}
              <Button type="submit" className="login-form-element" gradient disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
