import React from 'react';
import Header from './Header';
import SidebarMenu from './SidebarMenu';
import '../styles/Layout.css';

const Layout = ({ children, onMenuItemClick }) => {
  return (
    <div className="layout">
      <div className="header-wrapper">
        <Header onMenuItemClick={onMenuItemClick} />
      </div>
      <SidebarMenu />
      <div className="main-content">
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
