import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from './logo.PNG';
import '../styles/Header.css';
import Button from '../components/Button.js';

const Header = ({ onMenuItemClick }) => {
  const { isAuthenticated, logout, userType } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  
  return (
    <header className="header">
      <div className="logo-container" onClick={() => onMenuItemClick('home')}>
        <img src={logo} alt="Bigbill AR Logo" className="logo" />
        <span className="logo-text">Bigbill AR Portal</span>
      </div>
      {isAuthenticated && (
        <nav className="nav-container">
          <ul className="nav-links">
            <li><Link to="/home">Home</Link></li>
            {userType === 'admin' && (
              <li className="dropdown">
                <span>Bigbill Admin</span>
                <ul className="dropdown-content">
                  <li><Link to="/user-management">User Management</Link></li>
                  <li><Link to="/ar-management">AR Management</Link></li>
                </ul>
              </li>
            )}
            <li><Link to="/user-profile">User Profile</Link></li>
          </ul>
          <Button className="logout-button" onClick={handleLogout}gradient>
            Logout
          </Button>
        </nav>
      )}
    </header>
  );
};

export default Header;
