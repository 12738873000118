import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Button.css'; // Ensure this imports your CSS

const Button = ({ onClick, children, type = 'button', gradient }) => {
  return (
    <button 
      className={`custom-button ${gradient ? 'gradient-button' : ''}`} 
      onClick={onClick} 
      type={type}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  gradient: PropTypes.bool,
};

export default Button;
