import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import UserManagement from './pages/UserManagement';
import CreateUser from './pages/CreateUser';
import UserList from './pages/UserList';
import EditUser from './pages/EditUser';
import UserProfile from './pages/UserProfile';
import ARManagement from './pages/ARManagement';
import PayerList from './pages/PayerList';
import EditPayer from './pages/EditPayer';
import FacilityList from './pages/FacilityList';
import EditFacility from './pages/EditFacility';
import AddFacility from './pages/AddFacility';
import MiscWorklist from './pages/MiscWorklist';
import ChalahReviewPending from './pages/ChalahReviewPending';
import YetToSendQuery from './pages/YetToSendQuery';
import FacilityVerificationUpload from './pages/FacilityVerificationUpload';
import MailMergeUpload from './pages/MailMergeUpload';
import ARFollowUpWorklist from './pages/ARFollowUpWorklist';
import FacilityBalanceSummary from './pages/FacilityBalanceSummary';
import { setupInactivityTimer } from './inactivityTimer';
import SummaryView from './pages/SummaryView';
import QuerySent from './pages/QuerySent';
import ProcessAutomation from './pages/ProcessAutomation';
import InvoiceSender from './pages/InvoiceSender';
import WebsiteCredentials from './pages/WebsiteCredentials';
import AddWebsite from './pages/AddWebsite';
import UploadCSV from './pages/UploadCSV';
import EditWebsite from './pages/EditWebsite';
import Dashboard from './pages/Dashboard';
import BillingEdit from './pages/BillingEdit';
import StatusRecordsPage from './pages/StatusRecordsPage';
import StatusRecordsPageDB from './pages/StatusRecordsPageDB';
import BillingEditDB from './pages/BillingEditDB';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

const AppContent = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      logout();
      navigate('/login');
    };

    const cleanup = setupInactivityTimer(handleLogout);
    return () => {
      if (cleanup) cleanup();
    };
  }, [logout, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/home" element={<ProtectedRoute element={<HomePage />} />} />
      <Route path="/user-management" element={<ProtectedRoute element={<UserManagement />} />} />
      <Route path="/create-user" element={<ProtectedRoute element={<CreateUser />} />} />
      <Route path="/user-list" element={<ProtectedRoute element={<UserList />} />} />
      <Route path="/edit-user/:id" element={<ProtectedRoute element={<EditUser />} />} />
      <Route path="/user-profile" element={<ProtectedRoute element={<UserProfile />} />} />
      <Route path="/ar-management" element={<ProtectedRoute element={<ARManagement />} />} />
      <Route path="/payer-setup" element={<ProtectedRoute element={<PayerList />} />} />
      <Route path="/payers/:payerId/edit" element={<ProtectedRoute element={<EditPayer />} />} />
      <Route path="/facility-setup" element={<ProtectedRoute element={<FacilityList />} />} />
      <Route path="/facilities/:id/edit" element={<ProtectedRoute element={<EditFacility />} />} />
      <Route path="/facilities/add" element={<ProtectedRoute element={<AddFacility />} />} />
      <Route path="/misc-worklist" element={<ProtectedRoute element={<MiscWorklist />} />} />
      <Route path="/misc-task-4" element={<ProtectedRoute element={<ChalahReviewPending />} />} />
      <Route path="/misc-task-5" element={<ProtectedRoute element={<YetToSendQuery />} />} />
      <Route path="/misc-task-6" element={<ProtectedRoute element={<FacilityVerificationUpload />} />} />
      <Route path="/misc-task-7" element={<ProtectedRoute element={<MailMergeUpload />} />} />
      <Route path="/ar-follow-up" element={<ProtectedRoute element={<ARFollowUpWorklist />} />} />
      <Route path="/facility-balance-summary" element={<ProtectedRoute element={<FacilityBalanceSummary />} />} />
      <Route path="/summary-view" element={<ProtectedRoute element={<SummaryView />} />} />
      <Route path="/query-sent" element={<ProtectedRoute element={<QuerySent />} />} />
      <Route path="/process-automation" element={<ProtectedRoute element={<ProcessAutomation />} />} />
      <Route path="/invoice-sender" element={<ProtectedRoute element={<InvoiceSender />} />} />
      <Route path="/website-credentials" element={<ProtectedRoute element={<WebsiteCredentials />} />} />
      <Route path="/add-website" element={<ProtectedRoute element={<AddWebsite />} />} />
      <Route path="/upload-csv" element={<ProtectedRoute element={<UploadCSV />} />} />
      <Route path="/edit/:id" element={<ProtectedRoute element={<EditWebsite />} />} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/billingedit/:id" element={<ProtectedRoute element={<BillingEdit />} />} />
      <Route path="/status-records/:status" element={<ProtectedRoute element={<StatusRecordsPage  />} />} />
      <Route path="/db-status-records/:status" element={<ProtectedRoute element={<StatusRecordsPageDB  />} />} />
      <Route path="/billingeditdb/:id" element={<ProtectedRoute element={<BillingEditDB  />} />} />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
