let logoutTimer;

const resetLogoutTimer = (logoutCallback) => {
  if (logoutTimer) {
    clearTimeout(logoutTimer); // Clear any previous timer
  }
  logoutTimer = setTimeout(logoutCallback, 600000); // 10 minutes (600000 milliseconds)
};

const setupInactivityTimer = (logoutCallback) => {
  const resetTimer = () => resetLogoutTimer(logoutCallback);

  // Add event listeners to reset the timer on user interactions
  window.addEventListener('load', resetTimer);
  document.addEventListener('mousemove', resetTimer);
  document.addEventListener('keypress', resetTimer);
  document.addEventListener('click', resetTimer);
  document.addEventListener('scroll', resetTimer);
  document.addEventListener('touchstart', resetTimer); // Mobile touch support

  // Clean up the event listeners if needed (e.g., if you use this in a React effect hook)
  return () => {
    window.removeEventListener('load', resetTimer);
    document.removeEventListener('mousemove', resetTimer);
    document.removeEventListener('keypress', resetTimer);
    document.removeEventListener('click', resetTimer);
    document.removeEventListener('scroll', resetTimer);
    document.removeEventListener('touchstart', resetTimer);
  };
};

export { setupInactivityTimer };
