// src/pages/ARFollowUpWorklist.js
import React from 'react';
import Layout from '../components/Layout'; // Import Layout component
import MenuPage from '../components/MenuPage'; // Import MenuPage component
import '../styles/MiscWorklist.css'; // Import CSS styles for this page

const ARFollowUpWorklist = () => {
  // Define the menu items for the AR Follow-Up Worklist
  const menuItems = [
    { label: 'Facility Balance Summary', path: '/facility-balance-summary' },
    { label: 'Facility Balance Detail', path: '/summary-view' },
    { label: 'Insruance AR', path: '/ar-task-3' },
    { label: 'Pateint AR', path: '/ar-task-4' },
  ];

  return (
    <Layout> {/* Wrap content in the Layout component */}
      <MenuPage title="AR Follow-Up Worklist" menuItems={menuItems} />
    </Layout>
  );
};

export default ARFollowUpWorklist;
