import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from '../utils/axios'; // Import axios for API calls
import Layout from '../components/Layout'; // Import Layout component
import TableAdvanced from '../components/TableAdvanced'; // Import Table component
import Button from '../components/Button'; // Import Button component
import Modal from '../components/Modal'; // Import Modal component
import '../styles/SummaryView.css'; // Create this CSS file for specific styling

const SummaryView = () => {
    const [data, setData] = useState([]);
    const [selectedResponsibleType, setSelectedResponsibleType] = useState('all');
    const [selectedResponsible, setSelectedResponsible] = useState('all');
    const [responsibleOptions, setResponsibleOptions] = useState([]);
    const [lastEmailSent, setLastEmailSent] = useState(''); // State for last email sent
    const [isLoading, setIsLoading] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false); // State for tracking email sending
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate(); // Initialize the useNavigate hook

    const columns = [
        { Header: 'Patient Name', accessor: 'Patient_Name' },
        { Header: 'Claim Balance', accessor: 'Claim_Balance', Cell: ({ value }) => `$${value.toFixed(2)}` },
        { Header: 'Responsible', accessor: 'Responsible' },
        { Header: 'Date of Service', accessor: 'Date_of_Service' },
        { Header: 'Claim Number', accessor: 'Claim_Num' },
        { Header: 'Invoice Number', accessor: 'Invoice_Num' },
        { Header: 'Aging Range', accessor: 'Aging_Range_from_billed' },
        { Header: 'Comments', accessor: 'Comments' },
    ];

    // Fetch summary data from the backend
    const fetchSummaryData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/summary/', {
                params: {
                    responsible_type: selectedResponsibleType,
                    responsible: selectedResponsible,
                },
            });
            setData(response.data.data || []);
            setResponsibleOptions(response.data.responsible_options || []);
            setLastEmailSent(response.data.last_email_sent || 'No email sent'); // Set the last email sent
        } catch (error) {
            setModalMessage('Error fetching data.');
            setIsModalOpen(true);
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedResponsibleType, selectedResponsible]);

    useEffect(() => {
        fetchSummaryData();
    }, [fetchSummaryData]);

    const handleResponsibleTypeChange = (event) => {
        setSelectedResponsibleType(event.target.value);
        setSelectedResponsible('all'); // Reset responsible selection when type changes
    };

    const handleResponsibleChange = (event) => {
        setSelectedResponsible(event.target.value);
    };

    // Function to send follow-up email
    const sendFollowUpEmail = async () => {
        if (selectedResponsible === 'all') {
            setModalMessage('Please select a specific responsible to send a follow-up email.');
            setIsModalOpen(true);
            return;
        }

        setIsSendingEmail(true); // Set sending email state to true
        try {
            const response = await axios.post('/api/send-follow-up-email/', {
                facility: selectedResponsible,
                responsible: selectedResponsibleType,
            });
            setModalMessage(response.data.message);
        } catch (error) {
            setModalMessage('Error sending follow-up email.');
            console.error('Error:', error);
        } finally {
            setIsSendingEmail(false); // Reset sending email state
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Layout>
            <div className="summary-view">
                <h2>Summary View</h2>
                <div className="controls">
                    <select onChange={handleResponsibleTypeChange} value={selectedResponsibleType} className="dropdown">
                        <option value="all">All</option>
                        <option value="SNF">SNF</option>
                        <option value="CLINIC">Clinic</option>
                        <option value="PRISON">Prison</option>
                    </select>

                    {selectedResponsibleType !== 'all' && (
                        <select onChange={handleResponsibleChange} value={selectedResponsible} className="dropdown">
                            <option value="all">All</option>
                            {responsibleOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    )}

                    <Button onClick={fetchSummaryData} gradient className="fetch-button" disabled={isLoading}>
                        {isLoading ? 'Loading...' : 'Fetch Data'}
                    </Button>
                    
                    <Button onClick={sendFollowUpEmail} gradient className="email-button" disabled={isSendingEmail}>
                        {isSendingEmail ? 'Sending Email...' : 'Send Follow-Up Email'}
                    </Button>

                    {/* Button to navigate back to AR Follow-Up Worklist */}
                    <Button onClick={() => navigate('/ar-follow-up')} gradient className="back-button">
                        Go Back to AR Follow-Up Worklist
                    </Button>
                </div>

                {/* Display last email sent information below the buttons and above the table */}
                {lastEmailSent && (
                    <div className="last-email-sent">
                        <strong>Last Email Sent: </strong> {lastEmailSent}
                    </div>
                )}

                <TableAdvanced columns={columns} data={data} />

                {isModalOpen && (
                    <Modal show={isModalOpen} message={modalMessage} onClose={closeModal} />
                )}
            </div>
        </Layout>
    );
};

export default SummaryView;
