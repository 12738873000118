import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../utils/axios';
import Layout from '../components/Layout';
import Button from '../components/Button';
import '../styles/AddWebsite.css';

const EditWebsite = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [websiteData, setWebsiteData] = useState({
        website_name: '',
        website_url: '',
        username: '',
        password: '',
        security_questions: '',
        comments: '',
    });

    useEffect(() => {
        const fetchWebsiteData = async () => {
            try {
                const response = await axios.get(`/api/website-credentials/${id}/`);
                setWebsiteData(response.data);
            } catch (error) {
                console.error("Error fetching website data:", error);
            }
        };
        
        fetchWebsiteData();
    }, [id]); // Only re-run if `id` changes

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWebsiteData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleEditSubmit = async () => {
        try {
            await axios.post(`/api/website-credentials/edit/${id}/`, websiteData);
            navigate('/website-credentials');
        } catch (error) {
            console.error("Error updating website:", error);
        }
    };

    return (
        <Layout>
            <div className="add-website">
                <h2>Edit Website</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                        <label>Website Name</label>
                        <input
                            type="text"
                            name="website_name"
                            value={websiteData.website_name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Website URL</label>
                        <input
                            type="url"
                            name="website_url"
                            value={websiteData.website_url}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            name="username"
                            value={websiteData.username}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            value={websiteData.password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Security Questions</label>
                        <input
                            type="text"
                            name="security_questions"
                            value={websiteData.security_questions}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Comments</label>
                        <textarea
                            name="comments"
                            value={websiteData.comments}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="button-group">
                        <Button onClick={handleEditSubmit} gradient>Save Changes</Button>
                        <Button onClick={() => navigate('/website-credentials')} gradient>Cancel</Button>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default EditWebsite;
