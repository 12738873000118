import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios'; // Adjust the path based on your project structure
import Button from '../components/Button'; // Import Button component
import '../styles/EditPayer.css'; // Create appropriate CSS for styling

const EditPayer = () => {
  const { payerId } = useParams(); // Corrected variable name to match the URL parameter in App.js
  const [payer, setPayer] = useState({
    payer_code: '',
    payer_name: '',
    payer_type: '',
    payer_address: '',
    payer_city: '',
    payer_state: '',
    payer_zip: '',
    payer_phone: '',
    payer_email: '',
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch payer details
    const fetchPayer = async () => {
      try {
        const response = await axios.get(`/api/payers/${payerId}/`); // Use correct URL parameter
        setPayer(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching payer details:', error);
        setLoading(false); // Ensure loading state is reset on error
      }
    };

    fetchPayer();
  }, [payerId]); // Use correct dependency name

  const handleChange = (e) => {
    setPayer({
      ...payer,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/payers/${payerId}/edit/`, payer); // Use correct URL parameter
      alert('Payer updated successfully');
      navigate('/payer-setup'); // Redirect back to the PayerList page using the correct route
    } catch (error) {
      console.error('Error updating payer:', error);
      alert('Failed to update payer. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="edit-payer">
      <h2>Edit Payer</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Payer Code</label>
          <input
            type="text"
            name="payer_code"
            value={payer.payer_code}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer Name</label>
          <input
            type="text"
            name="payer_name"
            value={payer.payer_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer Type</label>
          <input
            type="text"
            name="payer_type"
            value={payer.payer_type}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer Address</label>
          <input
            type="text"
            name="payer_address"
            value={payer.payer_address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer City</label>
          <input
            type="text"
            name="payer_city"
            value={payer.payer_city}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer State</label>
          <input
            type="text"
            name="payer_state"
            value={payer.payer_state}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer ZIP</label>
          <input
            type="text"
            name="payer_zip"
            value={payer.payer_zip}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer Phone</label>
          <input
            type="text"
            name="payer_phone"
            value={payer.payer_phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Payer Email</label>
          <input
            type="email"
            name="payer_email"
            value={payer.payer_email}
            onChange={handleChange}
          />
        </div>
        <Button type="submit" gradient>Save Changes</Button>
      </form>
    </div>
  );
};

export default EditPayer;
