import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import UserForm from '../components/UserForm';
import Button from '../components/Button';
import axios from '../utils/axios'; // Import the custom Axios instance
import '../styles/UserForm.css'; // Import necessary styles

const CreateUser = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    empId: '',
    email: '',
    emailAddress2: '',
    phoneNumber: '',
    location: '',
    userType: 'user',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting form data:', formData); // Debugging log
      const response = await axios.post('/api/users/create/', formData); // Use the custom Axios instance
      if (response.status === 201) {
        navigate('/user-management');
      }
    } catch (error) {
      console.error('Error creating user', error);
      const humanReadableMessage = error.response?.data?.message || 'Failed to create user';
      setErrorMessage(humanReadableMessage);
    }
  };

  const handleBackToUserManagement = () => {
    navigate('/user-management');
  };

  return (
    <Layout>
      <div className="create-user">
        <h2>Create User</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <UserForm formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
        <Button onClick={handleBackToUserManagement} gradient>Back to User Management</Button>
      </div>
    </Layout>
  );
};

export default CreateUser;
