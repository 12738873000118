import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios'; // Import the axios instance
import Button from '../components/Button'; // Import Button component
import '../styles/EditFacility.css'; // Create appropriate CSS for styling

const EditFacility = () => {
  const { id } = useParams(); // Get the facility ID from the URL
  const [facility, setFacility] = useState({
    facility_name: '',
    facility_type: '',
    facility_group: '',
    facility_address: '',
    facility_city: '',
    facility_state: '',
    facility_zip: '',
    facility_phone: '',
    facility_fax: '',
    facility_email: '',
    facility_contact_first_name: '',
    facility_contact_last_name: '',
    facility_xray_rate: '',
    facility_us_rate: '',
    facility_ekg_rate: '',
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the facility details
    const fetchFacility = async () => {
      try {
        const response = await axios.get(`/api/facilities/${id}/`);
        setFacility(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching facility details:', error);
      }
    };

    fetchFacility();
  }, [id]);

  const handleChange = (e) => {
    setFacility({
      ...facility,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/facilities/${id}/edit/`, facility);
      alert('Facility updated successfully');
      navigate('/facility-setup');
    } catch (error) {
      console.error('Error updating facility:', error);
      alert('Failed to update facility. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="edit-facility">
      <h2>Edit Facility</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Facility Name</label>
          <input
            type="text"
            name="facility_name"
            value={facility.facility_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility Type</label>
          <input
            type="text"
            name="facility_type"
            value={facility.facility_type}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility Group</label>
          <input
            type="text"
            name="facility_group"
            value={facility.facility_group}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Facility Address</label>
          <input
            type="text"
            name="facility_address"
            value={facility.facility_address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="facility_city"
            value={facility.facility_city}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <input
            type="text"
            name="facility_state"
            value={facility.facility_state}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>ZIP Code</label>
          <input
            type="text"
            name="facility_zip"
            value={facility.facility_zip}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            name="facility_phone"
            value={facility.facility_phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Fax</label>
          <input
            type="text"
            name="facility_fax"
            value={facility.facility_fax}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            name="facility_email"
            value={facility.facility_email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Contact First Name</label>
          <input
            type="text"
            name="facility_contact_first_name"
            value={facility.facility_contact_first_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Contact Last Name</label>
          <input
            type="text"
            name="facility_contact_last_name"
            value={facility.facility_contact_last_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>X-ray Rate</label>
          <input
            type="text"
            name="facility_xray_rate"
            value={facility.facility_xray_rate}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>US Rate</label>
          <input
            type="text"
            name="facility_us_rate"
            value={facility.facility_us_rate}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>EKG Rate</label>
          <input
            type="text"
            name="facility_ekg_rate"
            value={facility.facility_ekg_rate}
            onChange={handleChange}
          />
        </div>
        <Button type="submit" gradient>Save Changes</Button>
      </form>
    </div>
  );
};

export default EditFacility;
