import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import '../styles/ARManagement.css';
import Button from '../components/Button';

const ARManagement = () => {
  return (
    <Layout>
      <div className="ar-management">
        <h2 className="ar-management-header">AR Management</h2>
        <div className="menu-container">
          <ul className="menu">
            <li><Link to="/ar-list">View AR List</Link></li>
            <li><Link to="/daily-summary">View Daily Summary</Link></li>
            <li><Link to="/summary-by-status">View Summary by Status</Link></li>
            <li><Link to="/overall-ar">View Overall AR</Link></li>
          </ul>
          <div className="home-button-container">
            <Link to="/home">
              <Button className="home-button" gradient>Home</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ARManagement;
