import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import TableAdvanced from '../components/TableAdvanced';
import Layout from '../components/Layout';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner
import Modal from '../components/Modal';
import '../styles/YetToSendQuery.css'; // Import the CSS file for styles

const YetToSendQuery = () => {
  const [claimData, setClaimData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [enhancing, setEnhancing] = useState(false);
  const [error, setError] = useState(null);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  // Fetch claim data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/yet-to-send-query/');
        setClaimData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Handle Export to Excel
  const handleExport = async () => {
    setExporting(true);
    try {
      const response = await axios.get('/api/yet-to-send-query/?export=true', {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'yet_to_send_query_export.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setModalMessage('Export successful!');
    } catch (err) {
      console.error('Failed to export data:', err);
      setModalMessage('Failed to export data. Please try again.');
    }
    setExporting(false);
    setShowModal(true);
  };

  // Handle Download Enhanced Data
  const handleDownloadEnhancedData = async () => {
    setEnhancing(true);
    try {
        const response = await axios.post('/api/yet-to-send-query/', {
            download_enhanced_data: true, // Pass the flag to indicate enhanced download
        }, {
            responseType: 'blob', // Important for file downloads
        });

        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'enhanced_yet_to_send_query_data.xlsx'); // Set the file name
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setModalMessage('Enhanced data download successful!');
    } catch (err) {
        console.error('Failed to download enhanced data:', err);
        setModalMessage('Failed to download enhanced data. Please try again.');
    }
    setEnhancing(false);
    setShowModal(true);
  };

  // Columns for the TableAdvanced component
  const columns = [
    { Header: 'Full Name', accessor: 'full_name' },
    { Header: 'DOB', accessor: 'dob' },
    { Header: 'Facility', accessor: 'facility' },
    { Header: 'Study Start', accessor: 'date_of_service' },
  ];

  if (error) return <div>{error}</div>;

  return (
    <Layout>
      <div className="yet-to-send-query-container">
      <h2 className="title">Yet To Send Query Claims</h2>
        {loading ? (
          <LoadingSpinner /> 
        ) : (
          <>
            <div className="actions">
              <Button onClick={handleExport} gradient style={{ width: '150px' }}>
                {exporting ? 'Exporting...' : 'Export to Excel'}
              </Button>
              <Button onClick={handleDownloadEnhancedData} gradient style={{ width: '150px' }}>
                {enhancing ? 'Downloading...' : 'Download Enhanced Data'}
              </Button>
              <Button onClick={() => navigate('/misc-worklist')} gradient style={{ width: '150px' }}>
                Back to Misc Worklist
              </Button>
            </div>
            <div className="table-container">
              <TableAdvanced columns={columns} data={claimData} />
            </div>
            {/* Modal to show response messages */}
            <Modal show={showModal} message={modalMessage} onClose={() => setShowModal(false)} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default YetToSendQuery;
