import axios from 'axios';
import { getCsrfToken } from './csrf'; // Import the CSRF token utility

// Set up the axios instance
const instance = axios.create({
  baseURL: 'https://billing.bigbilltech.com', // Change this to your EC2 public domain or IP
  withCredentials: true, // Ensure credentials are sent with the request
});

instance.interceptors.request.use((config) => {
  const token = getCsrfToken();
  if (token) {
    config.headers['X-CSRFToken'] = token; // Django expects this header for CSRF
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default instance;
