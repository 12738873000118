import React, { useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import '../styles/TableEdit.css'; // Use a separate CSS file for styling

const TableEdit = ({ columns, data, onSave, onCancel, onBack }) => {
    const [editableData, setEditableData] = useState(data);

    const handleEdit = (rowIndex, columnId, value) => {
        const updatedData = editableData.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...row,
                    [columnId]: value,
                };
            }
            return row;
        });
        setEditableData(updatedData);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setPageSize,
    } = useTable(
        {
            columns,
            data: editableData,
            initialState: { pageIndex: 0, pageSize: 50 }, // Default page size
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <table {...getTableProps()} className="custom-table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={column.isSorted ? (column.isSortedDesc ? 'descending' : 'ascending') : ''}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} style={{ position: 'relative' }}>
                                        {cell.column.isEditable ? (
                                            cell.column.editType === 'dropdown' ? (
                                                <select
                                                    value={cell.value}
                                                    onChange={e => handleEdit(rowIndex, cell.column.id, e.target.value)}
                                                    style={{ fontFamily: 'Montserrat, sans-serif' }} // Set font style
                                                >
                                                    {cell.column.options.map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        value={cell.value}
                                                        onChange={e => handleEdit(rowIndex, cell.column.id, e.target.value)}
                                                        style={{ fontFamily: 'Montserrat, sans-serif' }} // Set font style
                                                    />
                                                    {cell.column.clearable && (
                                                        <button
                                                            onClick={() => handleEdit(rowIndex, cell.column.id, '')}
                                                            style={{
                                                                marginLeft: '5px',
                                                                cursor: 'pointer',
                                                                backgroundColor: 'transparent',
                                                                border: 'none',
                                                                color: '#007bff',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            X
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        ) : (
                                            cell.render('Cell')
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                </button>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                    {[50, 100, 200].map(size => (
                        <option key={size} value={size}>
                            Show {size}
                        </option>
                    ))}
                </select>
            </div>
            <div className="table-edit-buttons">
                <button onClick={() => onSave(editableData)}>Save Changes</button>
                <button onClick={onCancel}>Cancel Changes</button>
                <button onClick={onBack}>Go Back to Misc Worklist</button>
            </div>
        </>
    );
};

export default TableEdit;
