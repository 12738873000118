import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import TableAdvanced from '../components/TableAdvanced';
import Layout from '../components/Layout';
import Button from '../components/Button';
import Modal from '../components/Modal'; // Import the Modal component
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner component
import '../styles/ChalahReviewPending.css';

const ChalahReviewPending = () => {
  const [claimData, setClaimData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [exporting, setExporting] = useState(false); // New state for export
  const [error, setError] = useState(null); // Handle error state
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  // Fetch claim data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/chalah-review-pending/');
        setClaimData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Handle Export to Excel
  const handleExport = async () => {
    setExporting(true);
    try {
      const response = await axios.get('/api/chalah-review-pending/?export=true', {
        responseType: 'blob', // Important for file downloads
      });

      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'chalah_review_claims_export.xlsx'); // Set the file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setModalMessage('Export successful!');
    } catch (err) {
      console.error('Failed to export data:', err);
      setModalMessage('Failed to export data. Please try again.');
    }
    setExporting(false);
    setShowModal(true); // Show the modal
  };

  // Handle Update Google Sheet
  const handleUpdateGoogleSheet = async () => {
    setUpdating(true);
    try {
      const response = await axios.post('/api/update-google-sheet/');
      setModalMessage(response.data.message);
    } catch (err) {
      setModalMessage('Failed to update Google Sheet. Please try again.');
    }
    setUpdating(false);
    setShowModal(true); // Show the modal
  };

  // Columns for the TableAdvanced component
  const columns = [
    { Header: 'Full Name', accessor: 'full_name' },
    { Header: 'ID/MRN', accessor: 'MRN' },
    { Header: 'DOB', accessor: 'dob' },
    { Header: 'Facility', accessor: 'facility' },
    { Header: 'Exam Description', accessor: 'exam_description' },
    { Header: 'Study Start', accessor: 'date_of_service' },
    { Header: 'ICD', accessor: 'icd_to_bill' },
    { Header: 'CPTs', accessor: 'cpts' },
    { Header: 'Remarks', accessor: 'coding_comments' },
  ];

  return (
    <Layout>
      <div className="chalah-review-pending-container">
      <h2 className="title">Chalah's Review Pending Claims</h2>
        {loading ? (
          <LoadingSpinner /> // Show the spinner inside the layout while loading
        ) : error ? (
          <div className="error-message">{error}</div> // Show error message if error occurs
        ) : (
          <>
            <div className="actions">
              <Button onClick={handleExport} gradient style={{ width: '150px' }}>
                {exporting ? 'Exporting...' : 'Export to Excel'}
              </Button>
              <Button onClick={handleUpdateGoogleSheet} gradient style={{ width: '150px' }}>
                {updating ? 'Updating...' : 'Update Google Sheet'}
              </Button>
              <Button onClick={() => navigate('/misc-worklist')} gradient style={{ width: '150px' }}>
                Back to Misc Worklist
              </Button>
            </div>
            <div className="table-container">
              <TableAdvanced columns={columns} data={claimData} />
            </div>
            {/* Modal to show response messages */}
            <Modal show={showModal} message={modalMessage} onClose={() => setShowModal(false)} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default ChalahReviewPending;
