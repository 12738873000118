import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Layout from '../components/Layout';
import Button from '../components/Button';
import axios from '../utils/axios';
import '../styles/UserProfile.css';

const UserProfile = () => {
  const { isAuthenticated } = useAuth();
  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    empId: '',
    email: '',
    emailAddress2: '',
    phoneNumber: '',
    location: '',
    userType: '',
  });
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('/api/users/profile/');
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError('Failed to fetch user profile. Please try again.');
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    }
  }, [isAuthenticated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/users/edit-profile/', formData);
      alert('Profile updated successfully');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    }
  };

  const handleResetPassword = async () => {
    const newPassword = window.prompt('Enter new password:');
    if (newPassword) {
      try {
        await axios.post('/api/users/reset-password/', { id: formData.id, password: newPassword });
        alert('Password has been reset successfully.');
      } catch (error) {
        console.error('Error resetting password:', error);
        setError('Failed to reset password. Please try again.');
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleNavigateHome = () => {
    navigate('/home');
  };

  return (
    <Layout>
      <div className="user-profile">
        <h2>User Profile</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="user-form">
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="form-group">
            <label>Employee ID</label>
            <input
              type="text"
              name="empId"
              value={formData.empId}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="form-group">
            <label>Alternate Email</label>
            <input
              type="email"
              name="emailAddress2"
              value={formData.emailAddress2}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="form-group">
            <label>Location</label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="form-group">
            <label>User Type</label>
            <input
              type="text"
              name="userType"
              value={formData.userType === 'admin' ? 'Bigbill Admin' : 'Bigbill User'}
              readOnly
            />
          </div>
          {isEditing && <Button type="submit" gradient>Save Changes</Button>}
        </form>
        <div className="button-group">
          {!isEditing && <Button onClick={handleEdit} gradient>Edit Profile</Button>}
          <Button onClick={handleResetPassword} gradient>Reset Password</Button>
          <Button onClick={handleNavigateHome} gradient>Home</Button>
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;
