import React from 'react';
import Layout from '../components/Layout';
import MenuPage from '../components/MenuPage';
import axios from '../utils/axios';
import '../styles/MiscWorklist.css';

const ProcessAutomation = () => {
  // Define the menu items for the MiscWorklist
  const menuItems = [
    { label: 'Send the Invoice Email', path: '/invoice-sender' },
  ];

  return (
    <Layout>
      <MenuPage title="Process Automation" menuItems={menuItems} />
    </Layout>
  );
};

export default ProcessAutomation;
