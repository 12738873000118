import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import TableAdvanced from '../components/TableAdvanced';
import Button from '../components/Button';
import axios from '../utils/axios';
import '../styles/UserList.css';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users/list/');
        console.log('Fetched users:', response.data); // Log the response to debug
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = useCallback((user) => {
    navigate(`/edit-user/${user.id}`);
  }, [navigate]);

  const handleDelete = useCallback(async (user) => {
    if (window.confirm(`Are you sure you want to delete user ${user.username}?`)) {
      try {
        await axios.post('/api/users/delete/', { id: user.id });
        setUsers((prevUsers) => prevUsers.filter((u) => u.id !== user.id));
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  }, []);

  const handleResetPassword = useCallback(async (user) => {
    const newPassword = window.prompt(`Enter new password for user ${user.username}:`);
    if (newPassword) {
      try {
        await axios.post('/api/users/reset-password/', { id: user.id, password: newPassword });
        alert(`Password for user ${user.username} has been reset successfully.`);
      } catch (error) {
        console.error('Error resetting password:', error);
        alert('Failed to reset password.');
      }
    }
  }, []);

  const handleBackToManagement = () => {
    navigate('/user-management');
  };

  // Define columns for the TableAdvanced component
  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Username',
      accessor: 'username',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Employee ID',
      accessor: 'empId',
    },
    {
      Header: 'Alternate Email',
      accessor: 'emailAddress2',
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="table-actions">
          <Button onClick={() => handleEdit(row.original)}>Edit</Button>
          <Button onClick={() => handleDelete(row.original)}>Delete</Button>
          <Button onClick={() => handleResetPassword(row.original)}>Reset Password</Button>
        </div>
      ),
    },
  ], [handleEdit, handleDelete, handleResetPassword]);

  // Prepare rows data for the table
  const data = useMemo(() => users.map((user) => ({
    id: user.id,
    username: user.username,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    empId: user.emp_id,
    emailAddress2: user.email_address2,
    phoneNumber: user.phone_number,
    location: user.location,
    userType: user.user_type === 'admin' ? 'Bigbill Admin' : 'Bigbill User',
  })), [users]);

  return (
    <Layout>
      <div className="user-list">
        <h2 className="user-list-header">User List</h2>
        <Button onClick={handleBackToManagement} gradient>Back to User Management</Button>
        <div className="custom-table-wrapper">
          <TableAdvanced columns={columns} data={data} />
        </div>
      </div>
    </Layout>
  );
};

export default UserList;
