import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import Layout from '../components/Layout';
import Button from '../components/Button';
import Modal from '../components/Modal';
import '../styles/InvoiceSender.css'; 

const InvoiceSender = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [progress, setProgress] = useState(0); // State for tracking progress
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleExcelChange = (e) => setExcelFile(e.target.files[0]);
  const handlePdfChange = (e) => setPdfFiles([...e.target.files]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(0); // Reset progress at the start

    const formData = new FormData();
    formData.append('excel_file', excelFile);
    pdfFiles.forEach((file) => formData.append('pdf_files', file));

    try {
      const response = await axios.post('/api/send_invoices/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          if (totalLength !== undefined) {
            setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      });
      setModalMessage(response.data.status || 'Emails sent successfully!');
    } catch (error) {
      console.error('Failed to send invoices:', error);
      setModalMessage('Failed to send invoices. Please try again.');
    }

    setProgress(0); // Reset progress after completion
    setShowModal(true);
  };

  return (
    <Layout>
      <div className="invoice-sender-container">
        <h2 className="title">Send Invoices</h2>
        <form onSubmit={handleSubmit} className="invoice-form">
          <label className="file-label">
            Excel File:
            <input
              type="file"
              accept=".xlsx"
              onChange={handleExcelChange}
              required
            />
          </label>
          <label className="file-label">
            PDF Files:
            <input
              type="file"
              accept=".pdf"
              multiple
              onChange={handlePdfChange}
              required
            />
          </label>
          <div className="actions">
            <Button
              type="submit"
              gradient
              style={{ width: '150px' }}
              disabled={!excelFile || pdfFiles.length === 0}
            >
              {progress > 0 ? `Uploading... ${progress}%` : 'Send Invoices'}
            </Button>
            <Button
              onClick={() => navigate('/process-automation')}
              gradient
              style={{ width: '150px' }}
            >
              Back to Process Automation
            </Button>
          </div>
          {progress > 0 && (
            <div className="progress-bar">
              <div
                className="progress"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}
        </form>
        <Modal
          show={showModal}
          message={modalMessage}
          onClose={() => setShowModal(false)}
        />
      </div>
    </Layout>
  );
};

export default InvoiceSender;
