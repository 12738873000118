import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios'; // Import your axios instance
import Layout from '../components/Layout';
import logo from '../components/logo.PNG';
import '../styles/HomePage.css';

const HomePage = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('home');
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get('/api/check-auth/');
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
        } else {
          navigate('/login'); // Redirect to login if not authenticated
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
        navigate('/login'); // Redirect on error
      }
    };

    checkAuthStatus();
  }, [navigate]);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  if (!isAuthenticated) {
    return null; // Optionally, show a loading indicator
  }

  return (
    <Layout onMenuItemClick={handleMenuItemClick}>
      <div className="home-content">
        {selectedMenuItem === 'home' && (
          <div className="home-layout">
            <div className="quick-links-box">
              <h3>Quick Links</h3>
              <ul>
                <li><a href="/misc-task-4">Chalah's Pending Claims</a></li>
                <li><a href="/misc-task-7">Send Query Email</a></li>
                <li><a href="/summary-view">Facility AR Detail</a></li>
                <li><a href="/facility-setup">Manage facilities</a></li>
              </ul>
            </div>
            <img src={logo} alt="Bigbill AR Logo" className="home-image" />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default HomePage;
