import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/MenuPage.css'; // Import the provided styles

const MenuPage = ({ title, menuItems }) => {
  const navigate = useNavigate();

  return (
    <div className="menu-page-container">
      <h2 className="menu-page-title">{title}</h2>
      <ul className="menu-page-menu">
        {menuItems.map((item, index) => (
          <li
            key={index}
            className="menu-item1"
            onClick={() => item.onClick ? item.onClick() : navigate(item.path)}
          >
            {item.label}
          </li>
        ))}
      </ul>
      <div className="menu-page-footer">
        <button onClick={() => navigate('/home')} className="menu-page-button">
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default MenuPage;
