import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios'; // Import the axios instance
import TableAdvanced from '../components/TableAdvanced'; // Import TableAdvanced component
import Layout from '../components/Layout'; // Import Layout component
import Button from '../components/Button'; // Import Button component
import '../styles/FacilityList.css'; // Import CSS for styling

const FacilityList = () => {
  const [facilities, setFacilities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch facilities from the API
    const fetchFacilities = async () => {
      try {
        const response = await axios.get('/api/facilities/');
        setFacilities(response.data);
      } catch (error) {
        console.error('Error fetching facilities:', error);
      }
    };

    fetchFacilities();
  }, []);

  const handleSearch = async () => {
    try {
      const response = await axios.get('/api/facilities/search/', {
        params: { q: searchQuery },
      });
      setFacilities(response.data);
    } catch (error) {
      console.error('Error searching facilities:', error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setModalMessage('Please select a file to upload.');
      setShowModal(true);
      return;
    }

    const formData = new FormData();
    formData.append('excel_file', selectedFile);

    axios.post('/api/facilities/upload/', formData)
      .then((response) => {
        setModalMessage('Upload successful');
        setFacilities(response.data.new_values);
      })
      .catch((error) => {
        setModalMessage('Error uploading facilities. Please try again.');
        console.error('Error uploading facilities:', error);
      })
      .finally(() => {
        setShowModal(true);
      });
  };

  const handleEdit = useCallback((facility) => {
    navigate(`/facilities/${facility.id}/edit/`);
  }, [navigate]);

  const handleDelete = useCallback(async (facility) => {
    if (window.confirm(`Are you sure you want to delete ${facility.facility_name}?`)) {
      try {
        await axios.delete(`/api/facilities/${facility.id}/`);
        setFacilities(facilities.filter((f) => f.id !== facility.id));
      } catch (error) {
        console.error('Error deleting facility:', error);
      }
    }
  }, [facilities]);

  const columns = useMemo(() => [
    { Header: 'Facility Name', accessor: 'facility_name' },
    { Header: 'Facility Type', accessor: 'facility_type' },
    { Header: 'Facility Group', accessor: 'facility_group' },
    { Header: 'Facility Address', accessor: 'facility_address' },
    { Header: 'Facility City', accessor: 'facility_city' },
    { Header: 'Facility State', accessor: 'facility_state' },
    { Header: 'Facility ZIP', accessor: 'facility_zip' },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="action-buttons">
          <Button onClick={() => handleEdit(row.original)} gradient>Edit</Button>
          <Button onClick={() => handleDelete(row.original)} gradient>Delete</Button>
        </div>
      ),
    },
  ], [handleEdit, handleDelete]);

  return (
    <Layout>
      <div className="facility-management">
        <h2>Facility Management</h2>
        <div className="facility-actions-container">
          {/* Search Group */}
          <div className="facility-group">
            <input 
              type="text" 
              placeholder="Search Facility" 
              value={searchQuery} 
              onChange={(e) => setSearchQuery(e.target.value)} 
            />
            <Button onClick={handleSearch} gradient>Search</Button>
          </div>
          
          {/* Upload Group */}
          <div className="facility-group">
            <label className="upload-label">
              Upload:
              <input type="file" onChange={handleFileChange} />
            </label>
            <Button onClick={handleUpload} gradient>Upload</Button>
          </div>

          {/* Add Facility Button */}
          <Button onClick={() => navigate('/facilities/add')} gradient>Add Facility</Button>

          {/* Go to Home Button */}
          <Button onClick={() => navigate('/home')} gradient>Go to Home</Button>
        </div>
        <div className="facility-table">
          <TableAdvanced columns={columns} data={facilities} />
        </div>

        {/* Modal for Upload Response */}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <p>{modalMessage}</p>
              <Button onClick={() => setShowModal(false)} gradient>Close</Button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default FacilityList;
