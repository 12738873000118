import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import axios from '../utils/axios'; // Import the axios instance
import TableAdvanced from '../components/TableAdvanced'; // Import TableAdvanced component
import Layout from '../components/Layout'; // Import Layout component
import Button from '../components/Button'; // Import Button component
import '../styles/PayerList.css'; // Create appropriate CSS for styling

const PayerList = () => {
  const [payers, setPayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); // State to hold the selected file
  const [modalMessage, setModalMessage] = useState(''); // State to hold the modal message
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Fetch payers from the API
    const fetchPayers = async () => {
      try {
        const response = await axios.get('/api/payers/');
        setPayers(response.data);
      } catch (error) {
        console.error('Error fetching payers:', error);
      }
    };

    fetchPayers();
  }, []);

  const handleExport = async () => {
    try {
      const response = await axios.get('/api/payers/export/', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payer_list.csv');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting payers:', error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get('/api/payers/search/', {
        params: { q: searchQuery },
      });
      setPayers(response.data);
    } catch (error) {
      console.error('Error searching payers:', error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setModalMessage('Please select a file to upload.');
      setShowModal(true);
      return;
    }

    const formData = new FormData();
    formData.append('excel_file', selectedFile);

    axios.post('/api/payers/upload/', formData)
      .then((response) => {
        setModalMessage('Upload successful');
        setPayers(response.data.new_values); // Update the list with new values
      })
      .catch((error) => {
        setModalMessage('Error uploading payers. Please try again.');
        console.error('Error uploading payers:', error);
      })
      .finally(() => {
        setShowModal(true);
      });
  };

  const handleEdit = useCallback((payer) => {
    // Navigate to edit payer
    window.location.href = `/payers/${payer.id}/edit/`;
  }, []);

  const handleDelete = useCallback(async (payer) => {
    if (window.confirm(`Are you sure you want to delete ${payer.payer_name}?`)) {
      try {
        await axios.delete(`/api/payers/${payer.id}/delete/`);
        setPayers(payers.filter((p) => p.id !== payer.id));
      } catch (error) {
        console.error('Error deleting payer:', error);
      }
    }
  }, [payers]);

  const columns = useMemo(() => [
    { Header: 'Payer Code', accessor: 'payer_code' },
    { Header: 'Payer Name', accessor: 'payer_name' },
    { Header: 'Payer Type', accessor: 'payer_type' },
    { Header: 'Payer Address', accessor: 'payer_address' },
    { Header: 'Payer City', accessor: 'payer_city' },
    { Header: 'Payer State', accessor: 'payer_state' },
    { Header: 'Payer ZIP', accessor: 'payer_zip' },
    { Header: 'Payer Phone', accessor: 'payer_phone' },
    { Header: 'Payer Email', accessor: 'payer_email' },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="action-buttons">
          <Button onClick={() => handleEdit(row.original)} gradient>Edit</Button>
          <Button onClick={() => handleDelete(row.original)} gradient>Delete</Button>
        </div>
      ),
    },
  ], [handleEdit, handleDelete]);

  return (
    <Layout>
      <div className="payer-management">
        <h2>Payer Management</h2>
        <div className="payer-actions">
          <input 
            type="text" 
            placeholder="Search Payer" 
            value={searchQuery} 
            onChange={(e) => setSearchQuery(e.target.value)} 
          />
          <Button onClick={handleSearch} gradient>Search</Button>
          
          <label className="upload-label">
            Upload:
            <input type="file" onChange={handleFileChange} />
          </label>
          
          <Button onClick={handleUpload} gradient>Upload</Button> {/* Upload Button */}
          
          <Button onClick={handleExport} gradient>Export</Button>
          <Button onClick={() => navigate('/home')} gradient>Go to Home</Button>
        </div>
        <div className="payer-table">
          <TableAdvanced columns={columns} data={payers} />
        </div>

        {/* Modal for Upload Response */}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <p>{modalMessage}</p>
              <Button onClick={() => setShowModal(false)} gradient>Close</Button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PayerList;
