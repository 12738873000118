import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import Layout from '../components/Layout';
import Button from '../components/Button';

const UploadCSV = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!file) {
            alert("Please select a CSV file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/website-credentials/upload/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log("Upload successful:", response.data);
            navigate('/website-credentials');
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <Layout>
            <h2>Upload CSV</h2>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <Button onClick={handleFileUpload} gradient>Submit</Button>
            <Button onClick={() => navigate('/website-credentials')} gradient>Cancel</Button>
        </Layout>
    );
};

export default UploadCSV;
