import React from 'react';
import '../styles/Modal.css'; // Import modal styles
import Button from '../components/Button';

const Modal = ({ show, message, onClose }) => {
  if (!show) return null; // Don't render the modal if `show` is false

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <Button onClick={onClose}gradient>Close</Button>
      </div>
    </div>
  );
};

export default Modal;
