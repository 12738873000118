import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios'; // Assuming you have this axios instance configured
import Layout from '../components/Layout';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner'; // Assuming you have a LoadingSpinner component
import '../styles/BillingEdit.css';

const BillingEditDB = () => {
  const { id } = useParams(); // Get the record ID from the URL
  const navigate = useNavigate();
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for fetching the record
  const [saving, setSaving] = useState(false); // Loading state for saving the record
  const [error, setError] = useState(null);

  // To store the original billing status before the edit
  const [originalBillingStatus, setOriginalBillingStatus] = useState('');

  // Dropdown options
  const dropdownOptions = {
    r_code: ['','R0070', 'R0075', 'NOT REQ'],
    r_code_mod: ['','NOT REQ', 'UN', 'UP', 'UQ', 'UR', 'US'],
    q_code_units: ['','NOT REQ', '1', '2'],
    coding_status: ['',
      'CODING COMPLETED',
      'PENDING FOR CODING',
      'CODING QUERY',
      'NOT READY TO CODE',
      'SERVICE NOT DONE',
      "CHALAH'S REVIEW PENDING",
    ],
    skilled_patient: ['',
      'SKILLED',
      'NOT SKILLED',
      'QUERY SENT & PENDING',
      'NOT A SNF PATIENT',
      'YET TO SEND THE QUERY',
      'EXCEPTION- NO EMAIL ADDRESS',
    ],
    eligibility_status: ['','Active', 'Pending', 'Inactive'],
    clone_claim_required: ['','NO', 'YES'],
    billing_status: ['',
      'BILLING COMPLETED',
      'READY TO BILL',
      'QUERY PENDING',
      'PENDING BILLING',
      'DO NOT BILL- CORE HEALTHCARE',
      'DO NOT BILL- COURTESY',
    ],
  };

  // Fetch record details
  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const response = await axios.get(`/api/db-record/${id}/`); // Corrected endpoint
        setRecord(response.data);
        setOriginalBillingStatus(response.data.billing_status); // Store original billing status
        setError(null);
      } catch (err) {
        console.error('Error fetching record:', err);
        setError('Failed to load record details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchRecord();
  }, [id]);

  const handleChange = (field, value) => {
    // Allow the user to type freely
    setRecord((prev) => ({ ...prev, [field]: value }));
  };
  
  // Validate and format the date on blur
  const handleBlur = (field) => {
    if (field === 'billed_date') {
      const rawValue = record[field];
      const parsedDate = parseDate(rawValue);
      if (parsedDate) {
        // Update the field with the formatted date
        setRecord((prev) => ({ ...prev, [field]: parsedDate }));
      } else if (rawValue.trim() !== '') {
        alert('Invalid date format. Please use yyyy-mm-dd, mm/dd/yyyy, or m/d/yy.');
      }
    }
  };
  
  // Function to parse date and convert to yyyy-mm-dd format
  const parseDate = (input) => {
    const dateFormats = [
      /^\d{4}-\d{2}-\d{2}$/, // yyyy-mm-dd
      /^\d{1,2}\/\d{1,2}\/\d{2}$/, // m/d/yy
      /^\d{1,2}\/\d{1,2}\/\d{4}$/, // m/d/yyyy
    ];
  
    if (!dateFormats.some((regex) => regex.test(input))) {
      return null; // Return null for invalid formats
    }
  
    let date;
  
    if (/^\d{4}-\d{2}-\d{2}$/.test(input)) {
      // yyyy-mm-dd format
      date = new Date(input);
    } else {
      // mm/dd/yyyy or m/d/yy formats
      const dateParts = input.split('/').map((part) => parseInt(part, 10));
      const [month, day, year] = dateParts;
  
      // Adjust for two-digit year
      const adjustedYear = year < 100 ? 2000 + year : year;
  
      date = new Date(adjustedYear, month - 1, day); // Month is zero-based in JS Date
    }
  
    if (date instanceof Date && !isNaN(date)) {
      return date.toISOString().split('T')[0]; // Convert to yyyy-mm-dd format
    }
  
    return null; // Return null for invalid dates
  };
  
  

  // Handle form submission (Save)
  const handleSave = async (e) => {
    e.preventDefault(); // Prevent the form from submitting and causing a reload
    setSaving(true); // Show the loading spinner for saving
    try {
      await axios.put(`/api/db-record/${id}/`, record); // Corrected endpoint
      alert('Record updated successfully!');
      // Navigate back to the list after saving
      navigate(`/db-status-records/${originalBillingStatus}`);
    } catch (err) {
      console.error('Error updating record:', err);
      alert('Failed to update record. Please try again.');
    } finally {
      setSaving(false); // Hide the loading spinner after the operation
    }
  };

  // Handle Cancel (Go to Dashboard)
  const handleCancel = () => {
    navigate('/dashboard');
  };

  // Handle "Back to List" (Navigate to the status records page)
  const handleBackToList = () => {
    navigate(`/db-status-records/${originalBillingStatus}`);
  };

  // If loading, show a spinner
  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error-message">{error}</div>;

  // Highlighting logic for the entire page or section
  const isSkilled = record?.skilled_patient === 'SKILLED';
  const isCodeChanged = record?.coding_comments === 'Code Changed';
  const pageClass = isSkilled || isCodeChanged ? 'billing-edit-page highlighted' : 'billing-edit-page';
  const fieldsetClass = isSkilled || isCodeChanged ? 'highlighted-fieldset' : '';

  return (
    <Layout>
      <div className={pageClass}>
        <h1>Edit Record</h1>
        <form onSubmit={handleSave}>
          {/* Patient Info Section */}
          <fieldset className={fieldsetClass}>
            <legend>Patient Info</legend>
            <div className="form-row">
              <label>
                Full Name:
                <input
                  type="text"
                  value={record.full_name || ''}
                  onChange={(e) => handleChange('full_name', e.target.value)}
                />
              </label>
              <label>
                DOB:
                <input
                  type="text"
                  value={record.dob || ''}
                  onChange={(e) => handleChange('dob', e.target.value)}
                />
              </label>
              <label>
                MRN:
                <input
                  type="text"
                  value={record.mrn || ''}
                  onChange={(e) => handleChange('mrn', e.target.value)}
                />
              </label>
              <label>
                Facility:
                <input
                  type="text"
                  value={record.facility || ''}
                  onChange={(e) => handleChange('facility', e.target.value)}
                />
              </label>
            </div>
          </fieldset>

          {/* Coding Info Section */}
          <fieldset className={fieldsetClass}>
            <legend>Coding Info</legend>
            <div className="form-row">
              <label>
                Exam CPT:
                <input
                  type="text"
                  value={record.exam_cpt || ''}
                  onChange={(e) => handleChange('exam_cpt', e.target.value)}
                />
              </label>
              <label>
                Exam Modality:
                <input
                  type="text"
                  value={record.exam_modality || ''}
                  onChange={(e) => handleChange('exam_modality', e.target.value)}
                />
              </label>
              <label>
                Exam Description:
                <input
                  type="text"
                  value={record.exam_description || ''}
                  onChange={(e) => handleChange('exam_description', e.target.value)}
                />
              </label>
              <label>
                Modality:
                <input
                  type="text"
                  value={record.exam_modality || ''}
                  onChange={(e) => handleChange('exam_modality', e.target.value)}
                />
              </label>
              <label>
                Insurance:
                <input
                  type="text"
                  value={record.insurance || ''}
                  onChange={(e) => handleChange('insurance', e.target.value)}
                />
              </label>
              <label>
                Facility Name:
                <input
                  type="text"
                  value={record.facility_name || ''}
                  onChange={(e) => handleChange('facility_name', e.target.value)}
                />
              </label>
            </div>
          </fieldset>

          {/* Coded Info Section */}
          <fieldset className={fieldsetClass}>
            <legend>Coded Info</legend>
            <div className="form-row">
              <label>
                Date of Service:
                <input
                  type="text"
                  value={record.date_of_service || ''}
                  onChange={(e) => handleChange('date_of_service', e.target.value)}
                />
              </label>
              <label>
                POS Type:
                <input
                  type="text"
                  value={record.pos_type || ''}
                  onChange={(e) => handleChange('pos_type', e.target.value)}
                />
              </label>
              <label>
                Modality:
                <input
                  type="text"
                  value={record.modality || ''}
                  onChange={(e) => handleChange('modality', e.target.value)}
                />
              </label>
              <label>
                Dos Month:
                <input
                  type="text"
                  value={record.dos_month || ''}
                  onChange={(e) => handleChange('dos_month', e.target.value)}
                />
              </label>
              <label>
                ICD to Bill:
                <input
                  type="text"
                  value={record.audited_icd || ''}
                  onChange={(e) => handleChange('audited_icd', e.target.value)}
                />
              </label>
              <label>
                R Code:
                <select
                  value={record.r_code || ''}
                  onChange={(e) => handleChange('r_code', e.target.value)}
                >
                  {dropdownOptions.r_code.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                R Code Mod:
                <select
                  value={record.r_code_mod || ''}
                  onChange={(e) => handleChange('r_code_mod', e.target.value)}
                >
                  {dropdownOptions.r_code_mod.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Q Code Units:
                <select
                  value={record.q_code_units || ''}
                  onChange={(e) => handleChange('q_code_units', e.target.value)}
                >
                  {dropdownOptions.q_code_units.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                CPT Codes:
                <input
                  type="text"
                  value={record.cpts || ''}
                  onChange={(e) => handleChange('cpts', e.target.value)}
                />
              </label>
              <label>
                Coding Status:
                <select
                  value={record.coding_status || ''}
                  onChange={(e) => handleChange('coding_status', e.target.value)}
                >
                  {dropdownOptions.coding_status.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Coding Comments:
                <input
                  type="text"
                  value={record.coder_comments || ''}
                  onChange={(e) => handleChange('coder_comments', e.target.value)}
                />
              </label>
              <label>
                Skilled Patient:
                <select
                  value={record.skilled_patient || ''}
                  onChange={(e) => handleChange('skilled_patient', e.target.value)}
                >
                  {dropdownOptions.skilled_patient.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </fieldset>

          {/* Billing Info Section */}
          <fieldset className={fieldsetClass}>
            <legend>Billing Info</legend>
            <div className="form-row">
            <label>
                Insurance Name:
                <input
                  type="text"
                  value={record.insurance_name || ''}
                  onChange={(e) => handleChange('insurance_name', e.target.value)}
                />
              </label>
              <label>
                Eligibility Status:
                <select
                  value={record.eligibility_status || ''}
                  onChange={(e) => handleChange('eligibility_status', e.target.value)}
                >
                  {dropdownOptions.eligibility_status.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Billing Status:
                <select
                  value={record.billing_status || ''}
                  onChange={(e) => handleChange('billing_status', e.target.value)}
                >
                  {dropdownOptions.billing_status.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Biller Comments:
                <input
                  type="text"
                  value={record.biller_comments || ''}
                  onChange={(e) => handleChange('biller_comments', e.target.value)}
                />
              </label>
              <label>
                Billed Date:
                <input
                    type="text"
                    value={record.billed_date || ''}
                    onChange={(e) => handleChange('billed_date', e.target.value)} // Allow free typing
                    onBlur={() => handleBlur('billed_date')} // Validate and format on blur
                />
                </label>

              <label>
                Claim #:
                <input
                  type="text"
                  value={record.claim_num || ''}
                  onChange={(e) => handleChange('claim_num', e.target.value)}
                />
              </label>
              
            </div>
          </fieldset>

          {/* Form Actions */}
          <div className="form-actions">
            <Button gradient type="submit" disabled={saving}>
              {saving ? 'Saving...' : 'Save'}
            </Button>
            <Button gradient onClick={handleCancel} disabled={saving}>
              Cancel
            </Button>
            <Button gradient onClick={handleBackToList} disabled={saving}>
              Back to List
            </Button>
          </div>
        </form>
        {/* Show a loading spinner while saving */}
        {saving && <LoadingSpinner />}
      </div>
    </Layout>
  );
};

export default BillingEditDB;
