import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button'; // Import the Button component
import '../styles/UserForm.css'; // Ensure this imports your CSS

const UserForm = ({ formData, handleChange, handleSubmit, isEdit }) => {
  return (
    <form onSubmit={handleSubmit} className="user-form">
      <div className="form-group">
        <label>Username</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
          readOnly={isEdit}
        />
      </div>
      {!isEdit && (
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
      )}
      <div className="form-group">
        <label>First Name</label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Last Name</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Employee ID</label>
        <input
          type="text"
          name="empId"
          value={formData.empId}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Alternate Email</label>
        <input
          type="email"
          name="emailAddress2"
          value={formData.emailAddress2}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Phone Number</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Location</label>
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>User Type</label>
        <select
          name="userType"
          value={formData.userType}
          onChange={handleChange}
          required
        >
          <option value="user">Bigbill User</option>
          <option value="admin">Bigbill Admin</option>
        </select>
      </div>
      <div className="form-group full-width">
        <div className="button-container">
          <Button type="submit" gradient>Submit</Button>
        </div>
      </div>
    </form>
  );
};

UserForm.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool, // New prop for edit mode
};

UserForm.defaultProps = {
  isEdit: false, // Default to false for create user
};

export default UserForm;
