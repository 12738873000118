import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import '../styles/UserManagement.css';
import Button from '../components/Button';

const UserManagement = () => {
  return (
    <Layout>
      <div className="user-management">
        <h2 className="user-management-header">User Management</h2>
        <div className="menu-container">
          <ul className="menu">
            <li><Link to="/create-user">Create User</Link></li>
            <li><Link to="/user-list">User List</Link></li>
          </ul>
          <div className="home-button-container">
            <Link to="/home">
              <Button className="home-button" gradient>Home</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserManagement;
