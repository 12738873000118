import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../utils/axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

    if (storedIsAuthenticated && storedUserType) {
      setIsAuthenticated(true);
      setUserType(storedUserType);
    }

    setAuthLoading(false);
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('/api/login/', { username, password });

      if (response.data.status === 'success') {
        setIsAuthenticated(true);
        setUserType(response.data.userType);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userType', response.data.userType);
      } else {
        throw new Error(response.data.message || 'Login failed');
      }
    } catch (error) {
      setIsAuthenticated(false);
      setUserType(null);
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('userType');
      throw error; // Let the calling component handle the error
    }
  };

  const logout = async () => {
    try {
      await axios.post('/api/logout/');
    } catch (error) {
      console.error('Server logout failed, clearing local session', error);
    } finally {
      setIsAuthenticated(false);
      setUserType(null);
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('userType');
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userType, login, logout, authLoading }}>
      {!authLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
