import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios'; // Adjust path based on your project structure
import Button from '../components/Button'; // Import the existing Button component
import Layout from '../components/Layout'; // Import the Layout component
import '../styles/FacilityVerificationUpload.css'; // Import the CSS file for styling

const FacilityVerificationUpload = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate(); // Initialize the navigate function

    // Handle file change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/upload-lookup-download/', formData, {
                responseType: 'blob', // For handling file download from response
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Handle file download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'facility_data.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload the file.');
        }
    };

    return (
        <Layout> {/* Use the Layout component */}
            <div className="facility-verification-upload">
                <h2>Facility Email Verification</h2>
                <input type="file" onChange={handleFileChange} />
                <div className="button-container"> {/* Wrapper div for buttons */}
                    <Button 
                        onClick={handleUpload} 
                        gradient 
                    >
                        Upload
                    </Button>
                    <Button 
                        onClick={() => navigate('/misc-worklist')} 
                        gradient 
                    >
                        Go to Misc Worklist
                    </Button>
                </div>
            </div>
        </Layout>
    );
};

export default FacilityVerificationUpload;
