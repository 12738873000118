// src/pages/MiscWorklist.js
import React from 'react';
import Layout from '../components/Layout'; // Import Layout component
import MenuPage from '../components/MenuPage'; // Import MenuPage component
import '../styles/MiscWorklist.css'; // Import CSS styles for this page

const MiscWorklist = () => {
  // Define the menu items for the MiscWorklist
  const menuItems = [
    { label: 'Review Billing Tracker', path: '/misc-task-1' },
    { label: 'Review Billed Data', path: '/misc-task-2' },
    { label: 'Core Healthcare Claims', path: '/misc-task-3' },
    { label: "Chalah's Review Pending Claims", path: '/misc-task-4' },
    { label: 'Query Sent Claims', path: '/query-sent' },
    { label: 'Yet To Send Query Claims', path: '/misc-task-5' },
    { label: 'Facility Email Verification', path: '/misc-task-6' },
    { label: 'Skilled Status Email', path: '/misc-task-7' }
  ];

  return (
    <Layout> {/* Wrap content in the Layout component */}
      <MenuPage title="Misc Worklist" menuItems={menuItems} />
    </Layout>
  );
};

export default MiscWorklist;
